import React from "react";
import { graphql } from "gatsby";
import Image from "gatsby-image";
import { Col } from "react-bootstrap";
import ReactMarkdown from "react-markdown/with-html";
import { LinkRenderer } from "../../utils/utilities";

//components
import PanelFoundation from "../../components/panels/PanelFoundation";
import Hero from "../../components/panels/Hero";
// import Approach from "../../components/panels/Approach";
// import MoreCards from "../../components/panels/MoreCards";
import ComponentHeader from "../../components/panels/ComponentHeader";
import SwitchComponent from "../../components/panels/Switch";
// import ListItem from "../../components/panels/list-item";
import useSetPageData from "../../utils/useSetPageData";
import SEO from "../../components/seo";

//styles
// import layoutStyle from "../../components/layouts/layout.module.scss";
import s from "./staff-augmentation.module.scss";

const ItConsultingPage = ({ location, data }) => {
  const {
    SEO: seo,
    hero,
    overview_description,
    it_overview_image,
    solutions,
    nitco_difference
  } = data.strapiPsIt;

  useSetPageData(location);

  return (
    <>
      <SEO
        {...{
          title: `${hero[0].main_heading} | Services`,
          description: seo.description
        }}
      />
      <Hero
        heading={hero[0].main_heading}
        icon={hero[0].service_icon.childImageSharp.fixed}
        // videoImage={hero[0].services_main_image.childImageSharp.fixed.src}
        // videoLink={"/videos/final-consulting.mp4"}
      />
      {/* <div style={{ marginTop: "40px" }}></div> */}
      <SwitchComponent
        contents={[
          {
            image: <Image fluid={it_overview_image.childImageSharp.fluid} />,
            content: (
              <ReactMarkdown
                source={overview_description}
                escapeHtml={false}
                renderers={{ link: LinkRenderer }}
              />
            )
          }
        ]}
      />
      <ComponentHeader
        rootClassName={`bg-white`}
        textClassName={`text-primary`}
        text={"Solutions"}
      />
      <SwitchComponent
        evenClassName={`bg-primary text-white`}
        oddClassName={`bg-white text-dark`}
        // evenBgColor={"purple"}
        // evenTextColor={"white"}
        // oddBgColor={"white"}
        // oddTextcolor={"gray"}
        contents={solutions.map((sol, i) => ({
          image: <Image fluid={sol.row_switch_image.childImageSharp.fluid} />,
          heading: sol.heading,
          content: (
            <ReactMarkdown
              source={sol.content}
              escapeHtml={false}
              renderers={{ link: LinkRenderer }}
            />
          )
        }))}
      />

      <ComponentHeader
        rootClassName={"bg-light"}
        textClassName={"text-primary"}
        text={"The NITCO Difference"}
        id={"nitco-difference"}
      />
      <PanelFoundation rootClassName={`panel-padding`}>
        <Col sm={12}>
          <ul className={`${s.smileIconList}`}>
            {nitco_difference.map((nitco, i) => (
              <li key={i}>
                <div>
                  <h4>{nitco.heading}</h4>
                  <div>
                    <p>{nitco.content}</p>
                  </div>
                </div>
                <div
                  className={`${s.iconListConnector}`}
                  style={
                    i === nitco_difference.length - 1 ? { display: "none" } : {}
                  }
                />
              </li>
            ))}
          </ul>
        </Col>
      </PanelFoundation>
      {/* <ComponentHeader
        rootClassName={`bg-light`}
        textClassName={`text-primary`}
        text={"Our Approach"}
      />
      <Approach /> */}
      {/* <ComponentHeader
        rootClassName={`bg-light`}
        textClassName={`text-primary`}
        text={"More Services"}
      />
      <MoreCards
        rootClassName={`bg-light`}
        itemClassName={`col-lg-6 col-md-6 col-sm-12`}
        cards={["SupportServices", "ProjectImplementations"]}
      /> */}
    </>
  );
};

export default ItConsultingPage;

export const pageQuery = graphql`
  {
    strapiPsIt {
      SEO {
        description
      }
      hero {
        main_heading
        main_subheading
        services_main_image {
          childImageSharp {
            fixed(width: 250) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        service_icon {
          childImageSharp {
            fixed(width: 100, height: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      overview_description
      it_overview_image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      versatile {
        heading
        content
        card_image {
          childImageSharp {
            fixed(width: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      solutions {
        heading
        content
        row_switch_image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      nitco_difference {
        heading
        content
      }
    }
  }
`;

// quality {
//   heading
//   content
//   card_image {
//     childImageSharp {
//       fixed(width: 100) {
//         ...GatsbyImageSharpFixed
//       }
//     }
//   }
// }
